@import url('https://fonts.googleapis.com/css2?family=Dr+Sugiyama&display=swap');

.navbar {
  height         : 100px;
  display        : flex;
  justify-content: center;
  align-items    : center;
  font-size      : 1.2rem;
  top            : 0;
  z-index        : 999;
}

.navbar-container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  height         : 150px;
  margin         : auto;
}

.navbar-logo {
  font-family: "Shippori Mincho B1", serif;
  color          : rgb(255, 255, 255);
  justify-self   : start;
  margin-left    : 20px;
  cursor         : pointer;
  text-decoration: none;
  font-size      : 1.5rem;
  display        : flex;
  align-items    : center;
  content: 'ITSSHUBHAOFFICIAL'
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size  : 1.8rem;
}

.nav-menu {
  display              : grid;
  grid-template-columns: repeat(4, auto);
  grid-gap             : 10px;
  list-style           : none;
  text-align           : center;
  width                : 70vw;
  justify-content      : end;
  margin-right         : 2rem;
}

.nav-links {
  color          : rgb(255, 255, 255);
  display        : flex;
  align-items    : center;
  text-decoration: none;
  padding        : 0.5rem 1rem;
  height         : 100%;
}

.nav-links:hover {
  /* background-color: rgb(192, 186, 186); */
  border-bottom   : 1px solid rgb(139, 136, 136);
  transition      : all 0.3s ease-out;
}

.fa-bars {
  color: rgb(168, 168, 168);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display        : flex;
    flex-direction : column;
    width          : 100%;
    height         : 90vh;
    position       : absolute;
    top            : 80px;
    left           : -100%;
    opacity        : 1;
    transition     : all 0.5s ease;
    justify-content: flex-start;
  }

  .nav-menu.active {
    background: #d1caca;
    left      : 0;
    opacity   : 1;
    transition: all 0.5s ease;
    z-index   : 1;
  }

  .nav-links {
    text-align: center;
    padding   : 2rem;
    width     : 100%;
    display   : table;
    color     : black;
  }

  .nav-links:hover {
    background-color: rgb(192, 186, 186);
    color           : #000000;
    border-radius   : 0;
  }

  .navbar-logo {
    position : absolute;
    font-size: 20px;
    top      : 0;
    left     : 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display  : block;
    position : absolute;
    top      : 0;
    right    : 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor   : pointer;
  }

  .fa-times {
    color    : rgb(168, 168, 168);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display         : block;
    text-align      : center;
    margin          : 2rem auto;
    border-radius   : 25px;
    width           : 80%;
    text-decoration : none;
    font-size       : 1.5rem;
    background-color: transparent;
    color           : rgb(0, 0, 0);
    padding         : 14px 20px;
    border          : 1px solid rgb(0, 0, 0);
    transition      : all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: rgb(143, 112, 112);
    color     : #242424;
    transition: 250ms;
  }
}

/* smartphones, iPhone, portrait 480x320 phones */
@media (min-width:320px)  {  

}

/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
@media (min-width:481px)  {  
  
}

/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
@media (min-width:641px)  { 
  .nav-menu{
    width: 52vw;
  }
}

/* low res ipad landscape + desktops */
@media (min-width:961px) {

}

/* laptops + desktops */
@media (min-width:1025px) {
    
}

/* hi rez laptops + desktops */
@media (min-width:1281px){

}