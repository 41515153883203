@import url("https://fonts.googleapis.com/css2?family=Shippori+Mincho+B1&display=swap");

/* .whole-page{
  background: none;
  background-color: #0E0301;
  transition: 2ms;
} */


.self-picture{
  max-height: 40vh;
  margin-bottom: 20px;;
}

.about-page{
  color: #dfdfdf;
  margin-top: 50px;
  height: fit-content;
}

.title {
  margin-top: 30px;
  line-height: 1.4em;
  text-align: center;
  font-size: 40px;
  font-family: "Shippori Mincho B1", serif;
}

.about-content{
  max-width: 40%;
  margin: auto;
  margin-top: 50px;
  text-align: center;
  font-size: 18px;
  height: fit-content;
}

.about-text{
  /* background-color: rgb(104, 103, 103); */
  background-color: rgba(0, 0, 0, 0.349);

  padding: 20px;
  border-radius: 10px;
}

@media screen and (max-width: 960px) {
  .about-page{
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .about-content{
    max-width: 90%;
  }
}