* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.whole-page {
  background: url("../Images/background.jpg")
    no-repeat center center fixed;
    /* background-color: #0E0301; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  transition: all 0.2s ease-in-out 0.2s;
  position: relative;
}

/* .footer{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
} */