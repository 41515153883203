.footer-container{
    padding: 1rem;
    color: white;
    text-align: center;
    margin: auto;
}

.social_links{
    padding-top: 13px;
    display: flex;
    justify-content: center;
}

.ig_icon{
    max-width: 27px;
}

.copyright_section{
    font-family: 'Sans serif';
    font-size: 15px;
    display: flex;
    justify-content: center;
}

.copyright_logo{
    max-width: 15px;
}

@media screen and (max-width: 960px) {
    .footer-container{
        font-size: 15px;;
        width: max-content;
    }
}