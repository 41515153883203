:root {
    --primary: rgb(185, 182, 182);
}

.btn {
    font-family  : 'Shippori Mincho B1', serif;
    padding      : 8px 20px;
    outline      : none;
    border       : none;
    cursor       : pointer;
}

.btn--primary {
    background: transparent;
    color     : #ffffff;
    border-bottom    : 1px solid var(--primary);
}

.btn--aboutpage {
    background: rgba(150, 150, 150, 0.5);
    color     : #ffffff;
    width     : 500px;
    font      : bold;
    border    : 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color           : rgb(255, 255, 255);
    /* padding      : 8px 20px; */
    border          : 1px solid var(--primary);
    transition      : all 0.3s ease-out;
}

.btn--medium {
    padding  : 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding  : 12px 26px;
    font-size: 20px;
}

.btn--extra-large {
    padding  : 12px 26px;
    font-size: 40px;
}

.btn--medium:hover,
.btn--large:hover {
    background: rgba(192, 192, 192, 0.3);
    color     : #ffffff;
    transition: all 0.3s ease-out;
    border-bottom    : 1px solid var(--primary);
}