@import url("https://fonts.googleapis.com/css2?family=Cinzel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap");

.pictureDiv {
  width: 95%;
  height: 100%;
  display: flex;
  margin: auto;
  margin-top: 30px;
}

#heading {
  font-family: "Cinzel", serif;
  text-align: center;
  font-size: 47px;
  padding-top: 30px;
  color: white;
}

#sub-heading {
  font-family: "Cinzel", serif;
  text-align: center;
  font-size: 15px;
  padding-bottom: 30px;
  color: white;
}

.pictures {
  height: 60vh;
  width: 30%;
  padding: 10px;
  display: flex;
  margin: 5px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(65, 30, 30, 0.2); */
  object-fit: contain;
}

.lifestyle {
  background: url("../Images/lifestyle_luke.webp")
    center center/cover no-repeat;
  flex: 1;
}

.graduation {
  background: url("../Images/graduation_coral.jpg")
    center center/cover no-repeat;
  flex: 1;
}

.events {
  background: url("../Images/events_50.jpg")
    center center/cover no-repeat;
  flex: 1;
}

.products {
  background: url("https://firebasestorage.googleapis.com/v0/b/itsshubhaofficial.appspot.com/o/Skateboard-min.jpg?alt=media&token=f0bd8533-c567-4865-9fee-b4e2916b8a8e")
    center center/cover no-repeat;
  flex: 1;
}

.pictures > span {
  font-family: "Shippori Mincho B1", serif;
  color: white;
  font-size: 50px;
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
}

.image-button {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  line-height: 40px;
  height: 40px;
  width: 110px;
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  transition: all 0.4s linear;
  text-align: center;
}

.image-button:hover {
  cursor: pointer;
  background-color: #243c4c;
  border-color: #243c4c;
}

@media screen and (max-width: 960px) {
  .pictureDiv {
    width: 95%;
    height: 100%;
    display: block;
    margin: auto;
    margin-top: 10px;
  }

  .pictures {
    height: 30vh;
    width: 100%;
    margin: 0px;
    margin-bottom: 10px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  }

  #heading {
    font-size: 30px;
    padding-top: 5px;
  }

  .pictures > span {
    font-size: 30px;
  }
}
